import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useRefresher } from 'hooks/useRefresher'
import PaymentList from './payments.list.component'

const Payment = ({ stripeOnly = false }) => {
	const refresher = useRefresher()

	return (
		<>
			<ListPageHeader breadcrumbs="Payments" />
			<PaymentList refresher={refresher} stripeOnly={stripeOnly} />
			<refresher.Toaster />
		</>
	)
}

export default Payment
