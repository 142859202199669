import InfoCard from '../../shared/info.card.component'

const CompanyInfoCard = ({ info, title }) => {
	const { address1, address2, city, name, state, zip, phone, fax, email } = info

	return (
		<InfoCard>
			{title && <h5 className="mb-3">{title}</h5>}
			<ul className="info-list list-unstyled mb-0">
				<li>
					<strong>{name}</strong>
				</li>
				<li>{address1}</li>
				{address2 !== '' ? <li>{address2}</li> : null}
				<li>
					{city}, {state} {zip}
				</li>
				{phone !== '' && (
					<li>
						<strong>Phone:</strong> {phone}
					</li>
				)}
				{fax !== '' && (
					<li>
						<strong>Fax:</strong> {fax}
					</li>
				)}
				{email !== '' && (
					<li>
						<strong>Email:</strong> {email}
					</li>
				)}
			</ul>
		</InfoCard>
	)
}

export default CompanyInfoCard
