// @ts-check

import { Col, Container, Row } from 'react-bootstrap'
import { HeaderBreadcrumb } from './header-breadcrumb.component'

/** @import { ReactElement, ReactNode } from 'react' */

/**
 * @param {{
 * 	actionColumn?: ReactElement,
 * 	breadcrumbs?: ReactNode,
 * }} props
 */
export const PageHeader = ({ actionColumn, breadcrumbs }) => {
	return (
		<Container fluid className="p-0">
			<Row>
				<Col>
					<HeaderBreadcrumb>
						{typeof breadcrumbs === 'string' ? (
							<HeaderBreadcrumb.Item active>
								{breadcrumbs}
							</HeaderBreadcrumb.Item>
						) : (
							breadcrumbs
						)}
					</HeaderBreadcrumb>
				</Col>
				{actionColumn}
			</Row>
		</Container>
	)
}
