// @ts-check

import { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import Filters from './filters.component'
import UserForm from './user.form.component'
import ContactForm from './contact.form.component'
import BulkUploadForm from './bulkupload.form.component'
import UsersList from './users.list.component'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useRefresher } from 'hooks/useRefresher'

const Users = () => {
	const [activeModal, setActiveModal] = useState(
		/** @type {Action | null} */ (null),
	)
	const [isClient, setIsClientOrCustomer] = useState(true)
	const [userData, setUserData] = useState(
		/** @type {UserListUser | null} */ (null),
	)
	const refresher = useRefresher()

	/**
	 * @param {UserListUser} user
	 */
	const showEditForm = (user) => {
		setUserData(user)
		if (user !== null) {
			setActiveModal('edit-user')
		}
	}

	return (
		<>
			<ListPageHeader
				breadcrumbs="User Management"
				action={
					<DropdownButton title="User Actions">
						<Dropdown.Item
							onClick={() => {
								setActiveModal('create-user')
							}}
						>
							Add New User
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setActiveModal('import-contacts')
							}}
						>
							Import Contacts
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setActiveModal('activate-contacts')
							}}
						>
							Activate Contacts
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setActiveModal('bulk-upload')
							}}
						>
							Bulk Upload
						</Dropdown.Item>
					</DropdownButton>
				}
			/>
			<Filters
				isClient={isClient}
				onClientClick={() => setIsClientOrCustomer(true)}
				onCustomerClick={() => setIsClientOrCustomer(false)}
			/>
			<UsersList
				isClient={isClient}
				onEditUser={showEditForm}
				refresher={refresher}
			/>
			<UserForm
				show={activeModal === 'create-user' || activeModal === 'edit-user'}
				mode={
					activeModal === 'create-user'
						? 'create'
						: activeModal === 'edit-user'
							? 'edit'
							: undefined
				}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
				userData={userData}
			/>
			<ContactForm
				show={
					activeModal === 'activate-contacts' ||
					activeModal === 'import-contacts'
				}
				mode={
					activeModal === 'activate-contacts'
						? 'activate'
						: activeModal === 'import-contacts'
							? 'import'
							: undefined
				}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
			/>
			<BulkUploadForm
				show={activeModal === 'bulk-upload'}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster header="User" />
		</>
	)
}

/**
 * @typedef {'create-user'
 * 	| 'edit-user'
 * 	| 'import-contacts'
 * 	| 'activate-contacts'
 * 	| 'bulk-upload'
 * } Action
 */

export default Users
