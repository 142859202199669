import { Dropdown, ListGroup } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'
import { formatCurrency, formatDate } from 'components/utilities'

export const accessors = (isClient) => [
	{
		Header: 'Payments List',
		columns: [
			{
				Header: 'Invoices',
				accessor: (item) => {
					const sortedIds = item.invoiceNumbers.split(',').sort()
					return (
						<ListGroup>
							{sortedIds.map((id, index) => (
								<ListGroup.Item key={index}>{id.trim()}</ListGroup.Item>
							))}
						</ListGroup>
					)
				},
			},
			...(isClient
				? [
						{
							Header: 'Cust ID',
							accessor: (item) => item.custNumCustID,
						},
					]
				: []),
			...(isClient
				? [
						{
							Header: 'Customer',
							accessor: (item) => item.custNumName,
						},
					]
				: []),
			{
				Header: 'Payment Date',
				accessor: (item) => formatDate(item.paymentDate),
			},
			{
				Header: 'Payment Amount',
				accessor: (item) => formatCurrency(item.totalAmount, item.currencyId),
			},
			{
				Header: 'Reference',
				accessor: (item) => {
					if (item.checkRef.startsWith('pi_') && isClient) {
						const stripeUrl =
							process.env.NODE_ENV === 'development'
								? 'https://dashboard.stripe.com/test'
								: 'https://dashboard.stripe.com'
						const stripeLink = `${stripeUrl}/payments/${item.checkRef}`
						return (
							<a href={stripeLink} target="_blank" rel="noopener noreferrer">
								{item.checkRef}
							</a>
						)
					}
					return (
						<>
							{item.checkRef.startsWith('pi_')
								? 'Pending Stripe Payment'
								: item.checkRef}
						</>
					)
				},
			},
			{
				Header: '⚡',
				accessor: (item) => item,
				Cell: ({ value: item, onRequestReceipt }) =>
					item.checkRef.startsWith('pi_') && (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => {
										onRequestReceipt(item.customerId, item.paymentIntent)
									}}
								>
									Request Receipt
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					),
			},
		],
	},
]
