import { Fragment, useEffect, useState } from 'react'
import {
	Accordion,
	Alert,
	Button,
	Col,
	Container,
	Form,
	Row,
	Spinner,
} from 'react-bootstrap'
import { addWeeks, formatISO } from 'date-fns'
import useAuth, { useCustomerId } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import CustomerInfoCard from './company.info.card.component'
import { formatCurrency } from 'components/utilities'
import {
	useEpicorCustomerService,
	useEpicorOrderService,
	useEpicorSharedService,
} from 'services/epicor'
import LoadingCards from '../../shared/loading/loading.card.component'
import AsyncPartTypeahead from '../../shared/part.search.component'
import _ from 'lodash'

const NewOrder = ({ onSubmitted }) => {
	const { currentCompany } = useAuth()
	const { settings } = useSettings()
	const EpicorCustomerService = useEpicorCustomerService()
	const EpicorOrderService = useEpicorOrderService()
	const EpicorSharedService = useEpicorSharedService()
	const orderDate = formatISO(new Date(), { representation: 'date' })
	const [needByDate, setNeedByDate] = useState(
		formatISO(addWeeks(new Date(), 2), { representation: 'date' }),
	)
	const [error, setError] = useState(null)
	const [poNumber, setPoNumber] = useState('')
	const [comments, setComments] = useState('')
	const [lineItems, setLineItems] = useState([
		{
			ident: new Date().getTime().toString(36),
			part: '',
			description: '',
			qty: 1,
			uom: '',
			unitPrice: 0,
			needByDate,
		},
	])
	const [currentCustomerAddress, setCurrentCustomerAddress] = useState(null)
	const [shipToNum, setShipToNum] = useState('')
	const [shipToAddresses, setShipToAddresses] = useState([])
	const [shipVias, setShipVias] = useState([])
	const [shipViaCode, setShipViaCode] = useState('')
	const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(true)
	const [savingOrder, setSavingOrder] = useState(false)

	const customerId = useCustomerId()

	const createOrderPayload = () => {
		const buildLineItems = () => {
			return lineItems.map((item) => {
				return {
					part: item.part,
					qty: item.qty,
					needByDate: item.needByDate,
				}
			})
		}
		return {
			order: {
				poNumber: poNumber,
				shipTo: shipToNum,
				date: orderDate,
				needByDate: needByDate,
				comment: comments,
				shipViaCode: shipViaCode,
				lineItems: buildLineItems(),
			},
		}
	}

	const addLineItem = () => {
		const newItems = [
			...lineItems,
			{
				ident: new Date().getTime().toString(36),
				part: '',
				description: '',
				qty: 1,
				uom: '',
				unitPrice: 0,
				needByDate: needByDate,
			},
		]
		setLineItems(newItems)
	}

	const orderTotalAmount = lineItems.reduce((total, item) => {
		return total + item.qty * item.unitPrice
	}, 0)

	const submit = async (event) => {
		event.preventDefault()
		setSavingOrder(true)

		try {
			const response = await EpicorOrderService.create(
				createOrderPayload(),
				currentCompany.company.environmentId,
				currentCompany.company.company1,
				customerId,
			)
			const { error } = response.data
			if (error) {
				setError(error[0]?.ErrorText ?? JSON.stringify(error))
			} else {
				onSubmitted(response.data)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setSavingOrder(false)
		}
	}

	const ShipToOptions = () => {
		return shipToAddresses.map((address) => (
			<option key={address.shipToNum} value={address.shipToNum}>
				{address.name} - {address.address1}
				{address.address2 ? `, ${address.address2}` : ' '}
				{address.city} {address.state} {address.zip}
			</option>
		))
	}

	const ShipViaOptions = () => {
		return shipVias.map((shipVia) => (
			<option key={shipVia.shipViaCode} value={shipVia.shipViaCode}>
				{shipVia.description}
			</option>
		))
	}

	const updateLineItem = (lineItem, fields) => {
		const index = lineItems.findIndex((item) => item.ident === lineItem.ident)
		if (index !== -1) {
			setLineItems((items) => {
				const newLineItems = [...items]
				newLineItems[index] = { ...items[index], ...fields }
				return newLineItems
			})
		}
	}

	const updatePartSelection = async (selectedPart, lineItem) => {
		if (selectedPart) {
			const updatedFields = {
				part: selectedPart.partNum,
				description: selectedPart.partDescription,
				uom: selectedPart.salesUM,
			}
			updateLineItem(lineItem, updatedFields)
			getPriceByQty({ ...lineItem, ...updatedFields })
		} else {
			updateLineItem(lineItem, { description: '' })
		}
	}

	const getPriceByQty = (lineItem) => {
		if (lineItem.part === '' || lineItem.qty === 0 || lineItem.uom === '') {
			return
		}
		EpicorSharedService.itemPriceByQty(
			customerId,
			shipToNum,
			lineItem.part,
			lineItem.qty,
			lineItem.uom,
		)
			.then((response) => {
				updateLineItem(lineItem, { unitPrice: response.data.basePrice })
				setError(null)
			})
			.catch(() => {
				setError('An error occurred, please check the part on the line item.')
			})
	}

	useEffect(() => {
		EpicorCustomerService.getInfo(customerId)
			.then((response) => {
				const {
					customerInfo,
					shipToAddresses: addresses,
					address,
				} = response.data
				setCurrentCustomerAddress(address)
				setShipToAddresses(addresses.sort((a, b) => a.name > b.name))
				setShipViaCode(customerInfo.ShipVia)
			})
			.catch((error) => {
				setError(error.message)
			})
			.finally(() => setLoadingCustomerInfo(false))

		EpicorSharedService.shipViaList(
			currentCompany.companyId,
			currentCompany.company.environmentId,
		)
			.then((response) => {
				setShipVias(response.data)
			})
			.catch((error) => {
				setError(error.message)
			})
	}, [EpicorCustomerService, EpicorSharedService, currentCompany, customerId])

	const shipToAddress = shipToAddresses.find(
		(item) => item.shipToNum === shipToNum,
	)

	const addressCards = loadingCustomerInfo ? (
		<LoadingCards cant={2} />
	) : (
		<Row>
			{currentCustomerAddress && (
				<Col>
					<CustomerInfoCard info={currentCustomerAddress} title="Bill To" />
				</Col>
			)}
			{shipToAddress && (
				<Col>
					<CustomerInfoCard info={shipToAddress} title="Ship To" />
				</Col>
			)}
		</Row>
	)

	return (
		<Form onSubmit={submit}>
			<Accordion defaultActiveKey={['Details', 'Lines']} alwaysOpen>
				<Accordion.Item eventKey="Address">
					<Accordion.Header>Address</Accordion.Header>
					<Accordion.Body>{addressCards}</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="Details">
					<Accordion.Header>Details</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Form.Group controlId="form.shipTo">
								<Form.Label>Shipping Address</Form.Label>
								<Form.Select
									name="shipTo"
									onChange={(e) => setShipToNum(e.target.value)}
									value={shipToNum || ''}
								>
									<ShipToOptions />
								</Form.Select>
							</Form.Group>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="form.needBy">
									<Form.Label>Need By</Form.Label>
									<Form.Control
										name="needBy"
										type="date"
										value={needByDate}
										min={formatISO(new Date(), { representation: 'date' })}
										onChange={(e) => setNeedByDate(e.target.value)}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="form.orderDate">
									<Form.Label>Order Date</Form.Label>
									<Form.Control
										name="orderDate"
										type="date"
										value={orderDate}
										disabled
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="form.po">
									<Form.Label>PO Number</Form.Label>
									<Form.Control
										name="po"
										type="string"
										placeholder="PO #"
										value={poNumber}
										onChange={(e) => setPoNumber(e.target.value)}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="form.shipVia">
									<Form.Label>Ship Via</Form.Label>
									<Form.Select
										name="shipVia"
										onChange={(e) => setShipViaCode(e.target.value)}
										value={shipViaCode || ''}
									>
										<ShipViaOptions />
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group controlId="form.comments">
							<Form.Label>Comments (optional)</Form.Label>
							<Form.Control
								as="textarea"
								rows={1}
								name="comments"
								placeholder="Please, put your comments here."
								value={comments}
								onChange={(e) => setComments(e.target.value)}
								style={{ overflow: 'auto' }}
							/>
						</Form.Group>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="Lines">
					<Accordion.Header>
						<div className="d-flex justify-content-between w-100">
							<span>Lines ({lineItems.length})</span>
							{lineItems.length > 0 && (
								<span className="text-end me-2">
									Order Total:{' '}
									{formatCurrency(
										orderTotalAmount,
										lineItems[0].currencyCodeCurrencyID,
									)}
								</span>
							)}
						</div>
					</Accordion.Header>
					<Accordion.Body>
						<Container>
							<Row className="d-none d-xl-flex mb-2">
								<Col xl={4}>Part</Col>
								<Col xl={2}>Qty</Col>
								<Col xl={1}>Price Per</Col>
								<Col xl={1}>Ext Price</Col>
								<Col xl={3}>Need By</Col>
								<Col xl={1}></Col>
							</Row>
							{lineItems.map((lineItem) => (
								<Fragment key={lineItem.ident}>
									<Row>
										<Col xs={2} className="d-xl-none mt-1">
											Part
										</Col>
										<Col xl={4} xs={10}>
											<AsyncPartTypeahead
												ident={lineItem.ident}
												onChange={(selected) => {
													updatePartSelection(selected, lineItem)
												}}
											/>
										</Col>
										<Col xs={12} className="order-xl-5 my-2 mb-xl-0">
											<div class="line-item-description-form-control">
												{lineItem.description || '\u00A0'}
											</div>
										</Col>
										<Col className="d-xl-none mb-2" xs={6}>
											Need By Date
										</Col>
										<Col xl={3} xs={6} className="order-xl-3 mb-2">
											<Form.Control
												name="needByLine"
												type="date"
												value={lineItem.needByDate}
												min={formatISO(new Date(), {
													representation: 'date',
												})}
												onChange={(e) => {
													updateLineItem(lineItem, {
														needByDate: e.target.value,
													})
												}}
											/>
										</Col>
										<Col className="d-xl-none" xs={6}>
											Qty
										</Col>
										<Col xl={2} xs={6}>
											<Form.Control
												name="qty"
												type="number"
												min={1}
												value={lineItem.qty}
												onChange={(e) => {
													const updatedFields = { qty: e.target.value }
													updateLineItem(lineItem, updatedFields)
													getPriceByQty({ ...lineItem, ...updatedFields })
												}}
											/>
										</Col>
										<Col xs={6} className="d-xl-none">
											Price Per ({lineItem.uom})
										</Col>
										<Col xl={1} xs={6} className="text-end mt-2">
											{formatCurrency(
												lineItem.unitPrice,
												lineItem.currencyCodeCurrencyID,
											)}
										</Col>
										<Col xs={6} className="d-xl-none">
											Ext Price
										</Col>
										<Col xl={1} xs={6} className="text-end mt-2">
											{formatCurrency(
												lineItem.qty * lineItem.unitPrice,
												lineItem.currencyCodeCurrencyID,
											)}
										</Col>
										<Col xl={1} className="order-xl-4">
											<Button
												variant="outline-danger"
												className="bi-x-square w-100 w-xl-auto"
												title="Delete Line"
												onClick={() => {
													setLineItems(
														lineItems.filter(
															(item) => item.ident !== lineItem.ident,
														),
													)
												}}
											/>
										</Col>
									</Row>
									<hr />
								</Fragment>
							))}
						</Container>
						{!_.isNull(error) && (
							<Alert
								dismissible
								variant="danger"
								onClose={() => setError(null)}
							>
								{error}
							</Alert>
						)}
						<Button
							className="bi bi-plus-square w-100"
							onClick={addLineItem}
							type="button"
							variant="outline-success"
							title="Add New Line"
						/>
						{settings.orderNewMessage && (
							<Alert variant="warning mt-auto">
								{settings.orderNewMessage}
							</Alert>
						)}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Button
				variant="primary"
				type="submit"
				disabled={savingOrder}
				className="mt-4"
			>
				{savingOrder ? (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">
							Saving Order, please wait...
						</span>
					</Spinner>
				) : (
					'Send Order'
				)}
			</Button>
		</Form>
	)
}

export default NewOrder
