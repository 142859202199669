import { Dropdown, Badge } from 'react-bootstrap'
import { WhiteShadowedCard } from '../shared/white.shadowed.card.component'

const ConnectionCard = ({
	environment,
	onRemove,
	onSetAsLive,
	onClick,
	onVerify,
}) => {
	const ConnectionCardContent = () => {
		return (
			<>
				<p className="mt-3 text-truncate">{environment.url}</p>
				<div className="d-flex justify-content-between align-items-end mt-3">
					<Badge
						bg={environment.verified ? 'success' : 'danger'}
						className="mb-0"
					>
						{environment.verified ? 'Connected' : 'Not Connected'}
					</Badge>
					{environment.isLive && <Badge bg="primary">Live</Badge>}
				</div>
			</>
		)
	}

	const ConnectionOptions = () => {
		return (
			<>
				<Dropdown.Item eventKey="1" onClick={() => onRemove(environment.id)}>
					Remove
				</Dropdown.Item>
				<Dropdown.Item eventKey="2" onClick={() => onVerify(environment.id)}>
					{environment.verified ? 'Reconnect' : 'Connect'}
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="2"
					onClick={() => onSetAsLive(environment.id, !environment.isLive)}
				>
					{environment.isLive ? 'Set as not Live' : 'Set as Live'}
				</Dropdown.Item>
			</>
		)
	}

	return (
		<WhiteShadowedCard
			key={environment.id}
			cardStyle={{ maxHeight: '200px' }}
			title={<h4>{environment.localName}</h4>}
			options={<ConnectionOptions />}
			onClick={onClick}
		>
			<ConnectionCardContent />
		</WhiteShadowedCard>
	)
}

export default ConnectionCard
