import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { downloadFile } from 'components/utilities'
import { useEpicorInvoiceService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import InvoicesTable from './invoices.table.component'
import InvoicePaymentModal from './invoice.payment.component'

const InvoicesList = () => {
	const [invoices, setInvoices] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [currentInvoice, setCurrentInvoice] = useState(null)
	const customerId = useCustomerId()

	const EpicorInvoiceService = useEpicorInvoiceService()

	const fetchInvoices = useCallback(async () => {
		setLoading(true)
		try {
			/** Replace with proper attribute here */
			const response = await EpicorInvoiceService.findAll(customerId)
			setInvoices(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorInvoiceService, customerId])

	const printInvoice = async (invoiceNum) => {
		setError(null)
		try {
			const response = await EpicorInvoiceService.print(customerId, invoiceNum)
			downloadFile(response)
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		if (customerId !== undefined) {
			fetchInvoices()
		}
	}, [customerId, fetchInvoices])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<InvoicesTable
					invoices={invoices}
					onPrintInvoice={printInvoice}
					onPayInvoice={setCurrentInvoice}
				/>
			)}
			{currentInvoice && (
				<InvoicePaymentModal
					customerId={customerId}
					invoice={currentInvoice}
					onClose={() => {
						setCurrentInvoice(null)
					}}
				/>
			)}
		</>
	)
}

export default InvoicesList
