import React, { useState, useEffect } from 'react'
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead'
import { Row } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useEpicorSharedService } from 'services/epicor'

const AsyncPartTypeahead = ({ ident, onChange }) => {
	const { currentCompany } = useAuth()
	const EpicorSharedService = useEpicorSharedService()
	const [options, setOptions] = useState([])
	const [part, setPart] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	// Load the part results.
	useEffect(() => {
		if (part.length < 3) {
			//Must have 3 characters or more
			setOptions([])
			return
		}

		const searchTimeout = setTimeout(async () => {
			setIsLoading(true)
			try {
				const response = await EpicorSharedService.partSearch(
					currentCompany.company.environmentId,
					currentCompany.companyId,
					part,
				)
				setOptions(response.data)
			} catch (error) {
				console.error('Error fetching results:', error)
			} finally {
				setIsLoading(false)
			}
		}, 300) // Adding a debounce to limit API calls

		return () => {
			clearTimeout(searchTimeout) // Cleanup the timeout
		}
	}, [EpicorSharedService, currentCompany, part])

	const filterBy = () => true

	return (
		<Typeahead
			id={ident}
			filterBy={filterBy}
			onInputChange={setPart}
			onChange={([selected]) => {
				onChange(selected)
			}}
			options={options}
			isLoading={isLoading}
			labelKey="partNum"
			placeholder="Part Number..."
			className="position-unset"
			renderMenu={(results, menuProps) => (
				<TypeaheadMenu
					{...menuProps}
					options={results}
					labelKey="partNum"
					className="m-2"
					style={{ left: 0, right: 0 }}
					renderMenuItemChildren={({ partNum, partDescription }) => (
						<>
							<Row
								style={{
									fontWeight: 'bold',
									textAlign: 'left',
									whiteSpace: 'wrap',
								}}
							>
								{partNum}
							</Row>
							<Row
								style={{
									overflow: 'hidden',
									whiteSpace: 'wrap',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									WebkitLineClamp: 3, // Adjust the number of lines to show before truncating
									WebkitBoxOrient: 'vertical',
								}}
							>
								{partDescription}
							</Row>
						</>
					)}
				/>
			)}
		/>
	)
}

export default AsyncPartTypeahead
