import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import HelpDeskForm from './helpdesk.form.component'
import HelpDeskList from './helpdesks.list.component'

const HelpDesk = () => {
	const [showForm, setShowForm] = useState(false)
	const refresher = useRefresher()
	const { settings } = useSettings()

	return (
		<>
			<ListPageHeader
				breadcrumbs="Cases"
				action={
					!settings.helpdeskDisableCreate && (
						<Button
							onClick={() => {
								setShowForm(true)
							}}
						>
							Create New Case
						</Button>
					)
				}
			/>
			<HelpDeskList refresher={refresher} />
			<HelpDeskForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster />
		</>
	)
}

export default HelpDesk
