// @ts-check

import { Col } from 'react-bootstrap'
import { PageHeader } from './page-header.component'

/** @import {  ReactNode } from 'react' */

/**
 * @param {{
 * 	action?: ReactNode,
 * 	breadcrumbs?: ReactNode,
 * 	pagination?: ReactNode,
 * }} props
 */
export const ListPageHeader = ({ action, breadcrumbs, pagination }) => {
	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			actionColumn={
				<Col className="d-flex flex-column align-items-end gap-2">
					{action}
					{pagination}
				</Col>
			}
		/>
	)
}
