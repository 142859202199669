import { Badge, Dropdown, Button } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'
import { formatDate } from '../utilities'

export const accessors = () => [
	{
		Header: 'Shipment List',
		columns: [
			{
				Header: 'Pack #',
				accessor: 'shipHeadPackNum',
			},
			{
				Header: 'Ship Date',
				accessor: (item) =>
					item.shipHeadShipDate != null
						? formatDate(item.shipHeadShipDate)
						: '',
			},
			{
				Header: 'Status',
				accessor: (item) =>
					item.shipHeadShipStatus === 'INVOICED' ? (
						<Badge bg="success">Invoiced</Badge>
					) : item.shipHeadShipStatus === 'SHIPPED' ? (
						<Badge bg="warning">Shipped</Badge>
					) : (
						<Badge bg="secondary">{item.shipHeadShipStatus}</Badge>
					),
			},
			{
				Header: 'Shipped From',
				accessor: 'plantState',
			},
			{
				Header: 'Ship To',
				accessor: (item) => {
					return (
						<>
							{item.calculatedAddress?.split('~').map((addrLine) => (
								<>
									{addrLine}
									<br />
								</>
							))}
						</>
					)
				},
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaDescription',
			},
			{
				Header: 'BOL',
				accessor: (item) =>
					item.shipHeadBOLNum === 0 ? '' : item.shipHeadBOLNum,
			},
			{
				Header: 'Tracking Number',
				accessor: (item) => {
					return (
						<Button
							href={item.calculatedTrackingURL}
							variant="link"
							target="_blank"
						>
							{item.shipHeadTrackingNumber}
						</Button>
					)
				},
			},
			{
				Header: '⚡',
				accessor: (item) => item,
				Cell: ({ value: item, onPrintPack, onPrintBOL }) => (
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									onPrintPack(item.shipHeadPackNum)
								}}
							>
								Download Pack
							</Dropdown.Item>
							{item.shipHeadBOLNum !== 0 && (
								<Dropdown.Item
									eventKey="2"
									onClick={() => {
										onPrintBOL(item.shipHeadPackNum, item.shipHeadBOLNum)
									}}
								>
									Download Bill of Lading
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				),
			},
		],
	},
]
