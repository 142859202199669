import { Button, Dropdown } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'

export const accessors = (isClient) => {
	const data = [
		{
			Header: 'Company',
			columns: [
				{
					Header: 'Company',
					accessor: 'companyName',
				},
			],
		},
		{
			Header: 'User',
			columns: [
				{
					Header: 'Name',
					accessor: ({ firstName, lastName }) => (
						<div className="d-flex align-items-start">
							<div className="circle d-none d-lg-inline-block me-3">
								{firstName.charAt(0)}
								{lastName.charAt(0)}
							</div>
							<div>
								{firstName} {lastName}
							</div>
						</div>
					),
				},
				{
					Header: 'Email',
					accessor: 'email',
				},
				{
					Header: 'Roles',
					accessor: 'roleName',
				},
				...(isClient
					? [] // If isClient is true, don't include the "Customer" column
					: [
							{
								Header: 'Customer',
								accessor: (u) => `(${u.custId}) ${u.customerName}`,
							},
						]),
				{
					Header: 'Status',
					accessor: ({ deleted }) =>
						deleted ? (
							<span className="badgeC badgeInActive">Inactive</span>
						) : (
							<span className="badgeC badgeActive">Active</span>
						),
				},
				{
					Header: 'Action',
					accessor: (user) => user,
					Cell: ({ value: user, onUserDeleted, onEditUser }) => (
						<ActionMenu
							user={user}
							onSelectEdit={onEditUser}
							onDeleted={onUserDeleted}
						/>
					),
				},
			],
		},
	]
	return data
}

function ActionMenu({ user, onSelectEdit, onDeleted }) {
	const UserDataService = useUserDataService()
	return (
		<Dropdown>
			<Dropdown.Toggle as={Button} variant="light" id="user-action-dropdown">
				<i className="bi bi-three-dots"></i>
			</Dropdown.Toggle>
			<Dropdown.Menu className="shadow">
				<Dropdown.Item
					eventKey="1"
					as="button"
					onClick={() => {
						onSelectEdit(user)
					}}
				>
					<i className="bi bi-pencil-square"></i> Edit user
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="2"
					as="button"
					onClick={async () => {
						user.deleted = !user.deleted
						await UserDataService.update(user.userId, user)
						onDeleted(user)
					}}
				>
					<i
						className={`bi ${
							user.deleted
								? 'bi-shield text-success'
								: 'bi-shield-slash text-danger'
						}`}
					>
						{user.deleted ? ' Activate user' : ' Deactivate user'}
					</i>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}
