import { Navbar, Nav } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import MenuOptions from './menu.options.component'
import Logo from '../shared/logo.component'

const SideBar = () => {
	const [expanded, setExpanded] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setExpanded(false)
	}, [location])

	return (
		<Navbar
			expand="md"
			className="navbar-dark flex-md-column"
			expanded={expanded}
		>
			<Navbar.Brand className="ms-2">
				<Link to="/home">
					<Logo />
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle onClick={() => setExpanded(!expanded)} />
			<Navbar.Collapse>
				<Nav className="flex-md-column">
					<MenuOptions />
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default SideBar
