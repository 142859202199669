import { useCallback, useEffect, useState } from 'react'
import { Alert, Carousel, Card, Button, Spinner, Badge } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import { useEpicorWidgetService } from 'services/epicor'
import { formatCurrency, formatDate } from 'components/utilities'

export default function Home() {
	const { currentCompany } = useAuth()
	const { settings } = useSettings()
	const EpicorWidgetService = useEpicorWidgetService()
	const [errorWidgetsQuery, setErrorWidgetsQuery] = useState(null)
	const [widgetsQuery, setWidgetsQuery] = useState(null)
	const [loadingWidgetsQuery, setLoadingWidgetsQuery] = useState(true)

	const isClient = currentCompany.isClient
	const customerId = currentCompany.attributes?.id

	const fetchWidgets = useCallback(async () => {
		setLoadingWidgetsQuery(true)
		try {
			const response = await EpicorWidgetService.getWidget(
				isClient ? 0 : customerId ?? 0,
				'query',
			)
			if (response.data) {
				setWidgetsQuery(response.data)
				setErrorWidgetsQuery('')
			} else setErrorWidgetsQuery('No Widgets returned!')
		} catch (error) {
			setErrorWidgetsQuery(error.message)
		} finally {
			setLoadingWidgetsQuery(false)
		}
	}, [EpicorWidgetService, customerId, isClient])

	useEffect(() => {
		if (customerId || isClient) {
			fetchWidgets()
		} else {
			setErrorWidgetsQuery('No Widgets returned')
		}
	}, [customerId, fetchWidgets, isClient])

	return (
		<>
			{isClient && (
				<>
					<p
						className="d-none d-md-block mx-auto mb-0"
						style={{ maxWidth: 600 }}
					>
						Welcome to ElevateERP—your gateway to seamless customer
						connectivity! Empower your customers with direct access to your
						data, reducing manual effort, enhancing accuracy, and delivering a
						superior self-service experience. Elevate efficiency, boost
						productivity, and transform the way you do business today!
					</p>
					<div className="d-flex flex-wrap justify-content-center align-items-center">
						<OpenOrdersWidget
							isLoading={loadingWidgetsQuery}
							errorMessage={errorWidgetsQuery}
							queryResult={widgetsQuery}
						/>
						<OpenInvoicesWidget
							isLoading={loadingWidgetsQuery}
							errorMessage={errorWidgetsQuery}
							queryResult={widgetsQuery}
						/>
						<ERPVersionWidget
							isLoading={loadingWidgetsQuery}
							errorMessage={errorWidgetsQuery}
							queryResult={widgetsQuery}
						/>
					</div>
				</>
			)}

			{!isClient && (
				<div className="carousel">
					{!settings.companyDisableCarosel && <IntroCarousel />}
					<div className="d-flex flex-wrap justify-content-between mt-3">
						{!settings.orderDisableWidget && (
							<OpenOrdersWidget
								isLoading={loadingWidgetsQuery}
								errorMessage={errorWidgetsQuery}
								queryResult={widgetsQuery}
							/>
						)}
						{!settings.invoiceDisableWidget && (
							<OpenInvoicesWidget
								isLoading={loadingWidgetsQuery}
								errorMessage={errorWidgetsQuery}
								queryResult={widgetsQuery}
							/>
						)}
						{!settings.shipmentDisableWidget && (
							<LastShipmentWidget
								isLoading={loadingWidgetsQuery}
								errorMessage={errorWidgetsQuery}
								queryResult={widgetsQuery}
							/>
						)}
					</div>
				</div>
			)}
		</>
	)
}

function IntroCarousel() {
	return (
		<Carousel interval={4500} className="d-none d-lg-block">
			<Carousel.Item>
				<img
					className="d-block w-100"
					src="http://jasonwoods.me/uploads/6/9/3/7/69377649/automation_orig.jpeg"
					alt="Time-Saving"
				/>
				<Carousel.Caption>
					<div className="carousel-text">
						<h3>Time-Saving</h3>
						<p>With ElevateERP, you can perform many tasks anytime anywhere.</p>
					</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
					className="d-block w-100"
					src="http://jasonwoods.me/uploads/6/9/3/7/69377649/reporting_orig.jpeg"
					alt="Your Information Instantly"
				/>
				<Carousel.Caption>
					<div className="carousel-text">
						<h3>Instant</h3>
						<p>All related information and documents are easily available.</p>
					</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
					className="d-block w-100"
					src="http://jasonwoods.me/uploads/6/9/3/7/69377649/page-layouts-6255498_orig.jpeg"
					alt="Manage"
				/>
				<Carousel.Caption>
					<div className="carousel-text">
						<h3>Manage</h3>
						<p>Create Orders, Pay Invoices, and more!</p>
					</div>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	)
}

function OpenOrdersWidget({ isLoading, errorMessage, queryResult }) {
	const { currentCompany } = useAuth()
	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>Open Orders</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						<h2>
							<Badge bg="dark">{queryResult?.calculatedOrderCount}</Badge>
						</h2>
						<h2>
							{formatCurrency(queryResult?.calculatedOrderAmt ?? 0, {
								withoutCents: true,
							})}
						</h2>
					</>
				)}
			</Card.Body>
			{!currentCompany.isClient && (
				<a href="/orders" className="btn btn-primary">
					Go
				</a>
			)}
		</Card>
	)
}

function OpenInvoicesWidget({ isLoading, errorMessage, queryResult }) {
	const { currentCompany } = useAuth()
	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>Open Invoices</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						<h2>
							<Badge bg="dark">{queryResult?.calculatedInvoiceCount}</Badge>
						</h2>
						<h2>
							{formatCurrency(queryResult?.calculatedInvoiceAmt ?? 0, {
								withoutCents: true,
							})}
						</h2>
					</>
				)}
			</Card.Body>
			{!currentCompany.isClient && (
				<a href="/invoices" className="btn btn-primary">
					Go
				</a>
			)}
		</Card>
	)
}

function ERPVersionWidget({ isLoading, errorMessage, queryResult }) {
	const { settings } = useSettings()

	const versionDaysOld = (
		(new Date() - new Date(queryResult?.calculatedVersionInstall)) /
		(1000 * 60 * 60 * 24)
	).toFixed()
	const versionDaysMax = parseInt(settings.versionDays || 90)

	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>ERP Version</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						<h2>
							<Badge bg="dark">{queryResult?.calculatedVersion01}</Badge>
						</h2>
						<h3>
							<Badge
								bg={versionDaysOld > versionDaysMax ? 'danger' : 'success'}
							>
								{versionDaysOld} Days Ago
							</Badge>
						</h3>
					</>
				)}
			</Card.Body>
		</Card>
	)
}

function LastShipmentWidget({ isLoading, errorMessage, queryResult }) {
	return (
		<Card className="card-widget">
			<Card.Body>
				<Card.Title>Last Shipment</Card.Title>
				{isLoading ? (
					<Spinner />
				) : errorMessage ? (
					<Alert variant="danger">{errorMessage}</Alert>
				) : (
					<>
						{queryResult?.calculatedLastShipment && (
							<h2>{formatDate(queryResult?.calculatedLastShipment)}</h2>
						)}
						<p>{queryResult?.shipViaDescription}</p>
						<p>
							{
								queryResult?.shipHeadTrackingNumber === ''
									? 'No Tracking Provided'
									: queryResult?.shipHeadTrackingNumber //TODO: Check if BAQ has these fields
							}
						</p>
					</>
				)}
			</Card.Body>
			<Button href="/shipments" className="btn btn-primary">
				Go
			</Button>
		</Card>
	)
}
