import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { downloadFile } from 'components/utilities'
import { useEpicorQuoteService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import QuotesTable from './quotes.table.component'

const QuotesList = ({ refresher }) => {
	const EpicorQuoteService = useEpicorQuoteService()
	const [quotes, setQuotes] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const fetchQuotes = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorQuoteService.findAll(customerId)
			setQuotes(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorQuoteService, customerId])

	const printQuote = async (quoteNum) => {
		setError(null)
		try {
			const response = await EpicorQuoteService.print(customerId, quoteNum)
			downloadFile(response)
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		if (customerId !== undefined) {
			fetchQuotes()
		}
	}, [customerId, fetchQuotes, refresher.id])

	return (
		<>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<QuotesTable quotes={quotes} onPrintQuote={printQuote} />
			)}
		</>
	)
}

export default QuotesList
