import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import ShipmentList from './shipments.list.component'

const Shipments = () => {
	return (
		<>
			<ListPageHeader breadcrumbs="Shipments" />
			<ShipmentList />
		</>
	)
}

export default Shipments
