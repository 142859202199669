import { useEffect, useMemo, useState } from 'react'
import { Tabs, Tab, Form, Alert, Button, Spinner } from 'react-bootstrap'
import { PageHeader } from 'components/shared/header/page-header.component'
import LoadingParagraphs from 'components/shared/loading/loading.paragraph.component'
import useAuth from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'

function getSettingsByGroup(settings) {
	const settingsByGroup = new Map()
	for (const setting of settings) {
		const groupSettings = settingsByGroup.get(setting.group) ?? []
		groupSettings.push(setting)
		settingsByGroup.set(setting.group, groupSettings)
	}
	return settingsByGroup
}

const Settings = () => {
	const { currentCompany } = useAuth()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [settingsByGroup, setSettingsByGroup] = useState(new Map())
	const [activeTabKey, setActiveTabKey] = useState()
	const [savingSettings, setSavingSettings] = useState(false)

	const { reloadSettings, updateSettings } = useSettings()

	useEffect(() => {
		setLoading(true)
		reloadSettings()
			.then((settings) => {
				setSettingsByGroup(getSettingsByGroup(settings))
				setActiveTabKey(settings[0]?.group)
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [currentCompany, reloadSettings])

	const saveSettings = async (event) => {
		event.preventDefault()
		setSavingSettings(true)
		try {
			const settings = await updateSettings(
				[...settingsByGroup.values()].flat(),
			)
			const updatedSettingsByGroup = getSettingsByGroup(settings)
			setSettingsByGroup(updatedSettingsByGroup)
			if (!updatedSettingsByGroup.has(activeTabKey)) {
				setActiveTabKey(settings[0]?.group)
			}
		} catch (error) {
			setError(error)
		} finally {
			setSavingSettings(false)
		}
	}

	return (
		<>
			<PageHeader breadcrumbs="Settings" />
			{error ? (
				<Alert dismissible variant="danger" onClose={() => setError(null)}>
					{error.toString()}
				</Alert>
			) : null}
			<Form onSubmit={saveSettings}>
				{loading ? (
					<LoadingParagraphs cant={5} animation="glow" />
				) : (
					<SettingsTabs
						settingsByGroup={settingsByGroup}
						onUpdateSettings={setSettingsByGroup}
						activeTabKey={activeTabKey}
						onSelectTab={setActiveTabKey}
					/>
				)}
				<Button variant="primary mt-3" type="submit" disabled={savingSettings}>
					{savingSettings ? (
						<>
							<Spinner animation="border" role="status" size="sm">
								<span className="visually-hidden">
									Saving Settings, please wait...
								</span>
							</Spinner>{' '}
							Saving...
						</>
					) : (
						'Save Settings'
					)}
				</Button>
			</Form>
		</>
	)
}

const SettingsTabs = ({
	settingsByGroup,
	onUpdateSettings,
	activeTabKey,
	onSelectTab,
}) => {
	const handleInputChange = (event, setting) => {
		const { value, checked } = event.target
		const updatedSettings = settingsByGroup.get(setting.group).map((item) => {
			if (item.id === setting.id) {
				return {
					...item,
					value: setting.type === 'switch' ? checked : value,
				}
			}
			return item
		})
		const updatedSettingsByGroup = new Map(settingsByGroup)
		updatedSettingsByGroup.set(setting.group, updatedSettings)
		onUpdateSettings(updatedSettingsByGroup)
	}

	//TODO: Add Settings Sorting by Group and Settings. Likely need a settingsgroup table.
	const sortedSettingsByGroup = useMemo(
		() =>
			[...settingsByGroup]
				.sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
				.map(([group, settings]) => [
					group,
					[...settings].sort((settingA, settingB) =>
						settingA.name.localeCompare(settingB.name),
					),
				]),
		[settingsByGroup],
	)

	return (
		<Tabs
			activeKey={activeTabKey}
			onSelect={(key) => {
				onSelectTab(key)
			}}
			className="mb-3"
		>
			{sortedSettingsByGroup.map(([groupName, settings]) => (
				<Tab key={groupName} eventKey={groupName} title={groupName}>
					{settings.map((setting) => (
						<Form.Group key={setting.id}>
							{setting.type === 'switch' && (
								<Form.Check
									key={setting.id}
									type={setting.type}
									id={setting.name}
									label={setting.label}
									className="enable-disable-switch"
									defaultChecked={setting.value}
									onChange={(e) => handleInputChange(e, setting)}
								/>
							)}
							{['color', 'number', 'text', null].includes(setting.type) && (
								<>
									<Form.Label>{setting.label}</Form.Label>
									<Form.Control
										type={setting.type}
										key={setting.id}
										id={setting.name}
										placeholder={setting.label}
										value={setting.value}
										onChange={(e) => handleInputChange(e, setting)}
									/>
								</>
							)}
							{['file'].includes(setting.type) && (
								<>
									<Form.Label>{setting.label}</Form.Label>
									<Form.Control
										type={setting.type}
										key={setting.id}
										id={setting.name}
										placeholder={setting.label}
										onChange={(e) => handleInputChange(e, setting)}
									/>
								</>
							)}
						</Form.Group>
					))}
				</Tab>
			))}
		</Tabs>
	)
}

export default Settings
