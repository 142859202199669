import { useState } from 'react'
import { Button } from 'react-bootstrap'
import ConnectionForm from './connection.form.component'
import ConnectionsList from './connections.list.component'
import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import { useRefresher } from 'hooks/useRefresher'

const EpicorConnections = () => {
	const [showForm, setShowForm] = useState(false)
	const [mode, setMode] = useState('create')
	const [envId, setEnvId] = useState(null)
	const refresher = useRefresher()

	const showEditForm = (id) => {
		setMode('edit')
		setEnvId(id)
		setShowForm(true)
	}

	const showVerifyForm = (id) => {
		setMode('connect')
		setEnvId(id)
		setShowForm(true)
	}

	return (
		<>
			<ListPageHeader
				breadcrumbs="Epicor Connections"
				action={
					<Button
						onClick={() => {
							setMode('create')
							setShowForm(true)
						}}
					>
						Add Connection
					</Button>
				}
			/>
			<ConnectionsList
				refresher={refresher}
				onCardClick={showEditForm}
				onVerify={showVerifyForm}
			/>
			<ConnectionForm
				show={showForm}
				mode={mode}
				onClose={() => {
					setShowForm(false)
					refresher.refresh()
				}}
				onOperationCompleted={refresher.refresh}
				envId={envId}
			/>
			<refresher.Toaster />
		</>
	)
}

export default EpicorConnections
