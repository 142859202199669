import { ListPageHeader } from 'components/shared/header/list-page-header.component'
import RMAList from './rma.list.component'

const RMAs = () => {
	return (
		<>
			<ListPageHeader breadcrumbs="RMAs" />
			<RMAList />
		</>
	)
}

export default RMAs
