import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { downloadFile } from 'components/utilities'
import { useEpicorRMAService } from 'services/epicor'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import RMAsTable from './rma.table.component'

const RMAsList = () => {
	const EpicorRMAService = useEpicorRMAService()
	const [rmas, setRMAs] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const printRMA = async (rmaNum) => {
		setError(null)
		try {
			const response = await EpicorRMAService.print(customerId, rmaNum)
			downloadFile(response)
		} catch (error) {
			setError(error.message)
		}
	}

	const fetchRMAs = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorRMAService.findAll(customerId)
			setRMAs(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorRMAService, customerId])

	useEffect(() => {
		if (customerId !== undefined) {
			fetchRMAs()
		}
	}, [customerId, fetchRMAs])

	return (
		<>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<RMAsTable rmas={rmas} onPrintRMA={printRMA} />
			)}
		</>
	)
}

export default RMAsList
